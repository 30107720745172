<template>
  <div
    ref="wrapper"
    class="item-form-wrapper"
  >
    <b-sidebar
      v-model="state"
      v-click-outside="onClickOutside"
      :width="`${width}px`"
      :no-header-close="true"
      right
      shadow
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="px-3 py-2">
        <data-table
          v-if="itemId"
          ref="dt"
          class="flex-grow-1 pt-2 text-nowrap"
          repo="talks.messages"
          :parent-id="itemId"
          :fields="detailFields"
          :order="order"
          :tbody-tr-class="getRowClass"
          :height="height"
          :limit="50"
        >
          <template #[`cell(sent)`]="{ data }">
            <span v-shtml="$dateFormat(data.item.sent, 'DD/MM/YYYY<br>HH:mm')" />
          </template>
          <template #[`cell(sender)`]="{ data }">
            <user-profile-item
              :user="data.item.sender"
            />
          </template>
          <template #[`cell(message)`]="{ data }">
            <div class="d-flex flex-column text-wrap" style="width: 260px;">
              <p v-if="data.item.message && data.item.message.trim() !== ''">
                {{ data.item.message }}
              </p>
              <b-link
                v-if="data.item.type === 'image'"
                target="_blank"
                :href="data.item.media_url"
              >
                <b-img
                  :src="data.item.media_url"
                  class="img-thumbnail"
                  style="width: 120px; height: 120px; object-fit: cover;"
                />
              </b-link>
            </div>
          </template>
          <template #[`cell(status)`]="{ data }">
            <div class="text-center">
              <b-badge v-if="data.item.status === 'unread'" variant="warning" class="p-1 mb-1 mr-1">
                {{ $t(`fTalk_status_${data.item.status}`) }}
              </b-badge>
              <b-badge v-else variant="info" class="p-1 mb-1 mr-1">
                {{ $t(`fTalk_status_${data.item.status}`) }}
              </b-badge>
            </div>
          </template>
          <template #[`cell(info)`]="{ data }">
            <div class="small">
              <b-badge v-if="data.item.deleted_admin" variant="danger" class="p-1 mb-1 mr-1">
                {{ $t('fTalk_l_deletedAdmin') }}
              </b-badge>
              <b-badge v-else-if="data.item.deleted_sender && data.item.deleted_receiver && !data.item.deleted_admin" variant="danger">
                {{ $t('fTalk_l_deleted') }}
              </b-badge>
              <b-badge v-else-if="data.item.deleted_receiver" variant="danger">
                {{ $t('fTalk_l_deletedReceiver') }}
              </b-badge>
              <b-badge v-else-if="data.item.deleted_sender" variant="danger">
                {{ $t('fTalk_l_deletedSender') }}
              </b-badge>
              <b-badge v-if="!!data.item.is_free_like" variant="danger" class="badge-outlined">
                {{ $t('pMsg_l_freeLike') }}
              </b-badge>
              <b-badge v-else-if="!!data.item.is_free_chat" variant="danger" class="badge-outlined">
                {{ $t('pMsg_l_freeChat') }}
              </b-badge>
              <b-badge v-if="!!data.item.scam_check_at" variant="warning" class="badge-outlined">
                {{ $t('pMsg_l_scam') }}
              </b-badge>
            </div>
          </template>
          <template #[`cell(actions)`]="{ data }">
            <div class="d-flex justify-content-end">
              <b-btn
                v-if="!data.item.deleted_admin"
                variant="danger"
                size="sm"
                :disabled="!canManage"
                @click="onRemove(data.item.id)"
              >
                <font-awesome-icon :icon="['fas', 'trash']" />
              </b-btn>
              <b-btn
                v-else
                variant="warning"
                size="sm"
                :disabled="!canManage"
                @click="onRestore(data.item.id)"
              >
                <font-awesome-icon :icon="['fas', 'trash-arrow-up']" />
              </b-btn>
            </div>
          </template>
        </data-table>
      </div>
      <template #header>
        <b-link v-if="isMobile" class="mr-2 text-info" @click="onCancel">
          <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
          <span v-shtml="parentTitle" />
        </b-link>
        - {{ displayTitle }}.
      </template>
      <template #footer>
        <div
          ref="footer"
          class="d-flex bg-light align-items-center px-3 py-2"
        >
          <div v-if="itemRepo">
            <button
              v-if="itemRepo.has_feedbacks === true"
              :disabled="true"
              variant="success"
              size="sm"
            >
              {{ $t('fTalk_feedback_enabled') }}
            </button>
            <b-btn
              v-else
              variant="success"
              size="sm"
              @click="onEnableFeedbacks(itemRepo.id)"
            >
              {{ $t('fTalk_feedback_disabled') }}
            </b-btn>
          </div>
          <strong class="mr-auto" />
          <b-btn variant="secondary" size="sm" :disabled="busy" @click="onCancel">
            {{ $t('eDoc_form_a_close') }}
          </b-btn>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GlobalForm } from '~/mixins/globalForm'

const globalForm = GlobalForm('talkPreview', {})

export default {
  ...globalForm,
  name: 'TalkPreviewForm',
  props: {
    ...globalForm.props
  },
  data () {
    return {
      ...globalForm.data(),
      detailFields: [
        {
          key: 'sent',
          label: this.$t('fTalk_f_sent'),
          sortable: true,
          thClass: 'col-1 text-center',
          class: 'text-left',
          formatter: (value, key, item) => {
            return this.$dateFormat(value, 'DD/MM/YYYY<br>HH:mm')
          }
        },
        {
          key: 'sender',
          label: this.$t('fTalk_f_sender'),
          sortable: true,
          thClass: 'col-3 text-center',
          class: 'text-left'
        },
        {
          key: 'message',
          label: this.$t('fTalk_f_message'),
          sortable: true,
          thClass: 'col-5 text-center',
          class: 'text-left'
        },
        {
          key: 'status',
          label: this.$t('fTalk_f_status'),
          sortable: true,
          thClass: 'col-1 text-center',
          class: 'text-left'
        },
        {
          key: 'info',
          label: this.$t('fTalk_f_info'),
          sortable: true,
          thClass: 'col-1 text-center',
          class: 'text-left'
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thClass: 'col-1 text-center',
          class: 'text-right'
        }
      ],
      order: {
        by: 'sent',
        dir: 'desc'
      },
      height: 500
    }
  },
  computed: {
    ...globalForm.computed,
    ...mapGetters({
      deviceInfo: 'device',
      pageTitle: 'layout/pageTitle'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    displayTitle () {
      let title
      if (this.title) {
        title = this.title
      } else {
        title = `Talk ${this.itemId !== null ? ` - ID:${this.itemId}` : ''}`
      }
      if (this.talk) {
        title = `${title} - ${this.talk.user_a.user_profile.display_name} - ${this.talk.user_b.user_profile.display_name}`
      }
      return title
    },
    parentTitle () {
      return this.pageTitle ? this.$t(this.pageTitle) : ''
    },
    talk () {
      const talks = this.getByName(`${this.repo}/show`)
      if (talks && talks.data.length) {
        return talks.data.find(i => i.id === this.itemId)
      }
      return false
    },
    canManage () {
      let can = false
      const authId = this.$auth.user.userId
      const authUserIsAdmin = this.$auth.user.roles.filter(i => ['admin', 'editor'].includes(i)).length > 0
      if (this.itemRepo) {
        if (this.itemRepo.is_support && (this.itemRepo.user_a.id === authId || this.itemRepo.user_b.id === authId)) {
          can = true
        } else if (!this.itemRepo.is_support) {
          can = true
        } else if (authUserIsAdmin) {
          can = true
        }
      }
      return can
    }
  },
  watch: {
    ...globalForm.watch
  },
  mounted () {
    if (window) {
      window.addEventListener('resize', this.calcHeight)
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.calcHeight()
      }, 1000)
    })
  },
  beforeDestroy () {
    if (window) {
      window.removeEventListener('resize', this.calcHeight)
    }
    // Flush related repos
    this.flush({ repo: 'talks.messages', method: 'index' })
  },
  methods: {
    ...globalForm.methods,
    ...mapActions({
      fill: 'forms/talkPreview/fill',
      enableFeedback: 'talk/enableFeedback'
    }),
    fillData () {
      if (this.itemRepo && this.itemRepo.id === this.itemId) {
        this.fill({
          id: this.itemId,
          data: {
            ...this.itemRepo,
            formData: this.formDataRepo
          }
        })
      }
    },
    async onRemove (id) {
      try {
        this.busy = true
        await this.call({
          repo: 'talks.messages',
          method: 'delete',
          id,
          parentId: this.itemId
        })
      } catch (e) {} finally {
        this.busy = false
      }
    },
    async onRestore (id) {
      try {
        this.busy = true
        await this.call({
          repo: 'talks.messages',
          method: 'update',
          id,
          parentId: this.itemId,
          payload: {
            deleted_admin: '0'
          }
        })
      } catch (e) {} finally {
        this.busy = false
      }
    },
    onEnableFeedbacks (id) {
      this.$confirmWarning({
        title: this.$t('fTalk_feedback_confirmTitle'),
        body: this.$t('fTalk_feedback_confirmBody', { id }),
        okTitle: this.$t('fTalk_feedback_confirmOk'),
        cancelTitle: this.$t('fTalk_feedback_confirmCancel')
      })
        .then(async (value) => {
          if (value) {
            await this.enableFeedback(id)
            this.$nextTick(() => {
              this.$fetch()
            })
          }
        })
    },
    getRowClass (item) {
      let cls = ''
      if (item && item.deleted_admin) {
        cls = 'bg-danger'
      } else if (item && (item.deleted_receiver || item.deleted_sender)) {
        cls = 'bg-warning'
      }
      return cls
    },
    calcHeight () {
      if (this.$refs.wrapper && window) {
        const $navbar = document.querySelector('.navbar')
        const navbarHeight = ($navbar && $navbar.clientHeight) || 0
        const windowHeight = window.innerHeight
        const footerHeight = (this.$refs.footer && this.$refs.footer.offsetHeight) || 0
        this.height = windowHeight - navbarHeight - footerHeight - 20
      }
    }
  }
}
</script>

<style scoped>

</style>
